
.homeModel{
    background-image: url("../../assets/images/modelAds.jpg");
    background-size: 100% 100%;
    height: 100%;
    max-width: 341px;
}

.homeModel a{
    color: #ffffff00;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ff000000;
    padding: 7px;
    border-radius: 50%;
    width: 35px;
    height: 34px;
    
}

.games_img {
    background: url(../../assets/images/logos/gamezroid/game-bg.ff87e0d1.webp) center
      center no-repeat !important;
    height: 300px;
    background-size: 300px;
  }

.sub_text
{
    font-weight: unset;
}
.free_text
{
    font-size: 20px;
    font-weight: bolder;
    color: #d42323;
    padding: 0;
    margin: 0;
}

.singupLogo
{
    background: url("../../assets/images/grandLogo.png") center center no-repeat!important;
    height: 65px!important;
}

.sub-sucess , .messageModal
{
    color: #16e216;
    font-size: 20px;
}

.messageModal
{
    text-align: center;
    font-weight: 700;
}