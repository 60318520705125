.game-list-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: auto;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-item {
  box-sizing: border-box;
  padding: 10px;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-button.prev {
  left: 10px;
}

.slider-button.next {
  right: 10px;
}
