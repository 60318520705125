
.searchIcon{
    background: url("../../../../assets/icons/big-search.svg") left center no-repeat;
    position: absolute;
    z-index: 2;
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    text-align: center;
}
.searchContainer{
    width: 33%;
    display: inline-flex;
    line-height:2.25 !important;
    height:none !important

}
.sres{
   
    padding: 5px 15px !important;
    display: block;
}
.sres img{
    width: 70px;
    outline: none!important;
    margin-right: 10px;
}
.sres p{
margin: 0;
color: #fff;
text-align: left;
text-align: initial;
margin: 0;
line-height: 15px;
font-size: 12px;
}

.erroeMss{
    line-height:2.25 !important;
    height:none !important
}