.containerComp {
  background: var(--homepageBgCompetition);
  background-size: cover; }

.compbuttons {
  display: flex;
  flex-direction: row;
  border: 1px solid red; }

.compbuttons > div {
  flex: 50%;
  border: 1px solid green; }

.leaderboard {
  background: var(--leaderboard-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: left; }

.tos {
  background: var(--help-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: right; }

.compbtn button {
  width: 70px;
  height: 70px;
  cursor: pointer;
  border: none;
  outline: none; }

.moreGame {
  width: 260px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  background-color: transparent; }

.compHomeGames {
  width: 56% !important;
  margin-top: 2px !important;
  flex-wrap: wrap; }

.btnspage {
  min-height: 85px;
  width: 56%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.btnhomeramdan {
  background: url("../../assets/images/HomepagecomGamezroid/homebtbg.png") no-repeat center center;
  background-size: contain;
  color: var(--textColor);
  padding: 10px;
  height: 69px;
  line-height: 9px;
  width: 110px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-family: SourceSansPro-Bold;
  transition: all ease 0.4s; }

.btnhomeramdan p {
  margin-top: 11px; }

.imgGame img {
  width: 100px;
  height: 100px; }

.gameCompName {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
  padding: 2px 7px 9px 7px;
  text-transform: uppercase; }

.coins {
  width: 100px;
  height: 75px;
  position: absolute;
  top: 22%;
  left: 24%; }

body[dir='rtl'] .btnhomeramdan {
  font-size: 20px; }

@media (max-width: 1024px) {
  .compHomeGames {
    width: 61% !important; }
  .coins {
    left: -26px; } }

@media (max-width: 950px) {
  .compHomeGames {
    width: 30% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; } }

@media (max-width: 768px) {
  .btnspage {
    width: 62%; }
  .compbtn {
    width: 65% !important; }
  .spaceTop {
    height: 3vh !important; }
  .compHomeGames {
    width: 65% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; }
  .imgGame img {
    width: 90px;
    height: 90px; }
  .section-home {
    width: 72% !important; } }

@media (max-width: 540px) {
  .btnspage {
    width: 75%; }
  .containerComp {
    height: 719px !important;
    background-size: cover; }
  .compHomeGames {
    width: 77% !important; }
  .spaceTop {
    height: 7vh !important; }
  .section-home {
    width: 86% !important; }
  .compbtn {
    width: 80% !important; } }

@media (max-width: 418px) {
  .btnspage {
    width: 100%; }
  .containerComp {
    min-height: 112vh;
    background-size: cover; }
  .compbtn {
    width: 100% !important; }
  .spaceTop {
    height: 6vh !important; } }

@media (max-width: 418px) {
  .containerComp {
    height: 873px !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; } }

@media (max-width: 411px) {
  .spaceTop {
    height: 2vh !important; }
  .containerComp {
    background-size: cover; } }

@media (max-width: 400px) {
  .containerComp {
    height: 838px !important;
    background-size: cover; } }

@media (max-width: 376px) {
  .containerComp {
    height: 814px !important;
    min-height: 108vh !important;
    background-size: cover; }
  .coins {
    top: 102px; } }

@media (max-width: 360px) {
  .spaceTop {
    height: 5vh !important; }
  .coins {
    top: 120px; } }

@media (max-width: 320px) {
  .links-SectionCompetition {
    width: 98% !important; }
  .containerComp {
    height: 567px !important;
    min-height: 130vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .imgGame img {
    width: 75px;
    height: 75px; }
  .coins {
    top: 100px;
    left: -3px; }
  .btnhomeramdan {
    width: 100px; }
  .headerCompetion {
    margin-top: 0 !important; } }

@media (max-width: 280px) {
  .containerComp {
    height: 651px !important;
    min-height: 114vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .links-SectionCompetition {
    width: 100% !important; }
  .logoComp {
    width: 130px !important; } }

@media (max-device-width: 1024px) and (orientation: landscape) {
  .containerComp {
    min-height: 109vh !important; } }

@media (max-device-width: 993px) and (orientation: landscape) {
  .containerComp {
    min-height: 170vh !important; } }

@media (max-device-width: 882px) and (orientation: landscape) {
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 823px) and (orientation: landscape) {
  .containerComp {
    min-height: 172vh !important; } }

@media (max-device-width: 768px) and (orientation: landscape) {
  .containerComp {
    min-height: 184vh !important; } }

@media (max-device-width: 640px) and (orientation: landscape) {
  .spaceTop {
    height: 4vh !important; }
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 653px) and (orientation: landscape) {
  .spaceTop {
    height: 0 !important; }
  /*.containerComp {
        min-height: 226vh !important;
    }*/ }

.ice {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 200;
  overflow: hidden;
  pointer-events: none; }

.g-snows {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0; }

.g-snows > li {
  opacity: 0;
  position: absolute;
  top: 0;
  border-radius: 100%;
  background: url("../../assets/images/competition/partical1.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  animation-name: snow-drop;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards; }
  .g-snows > li:nth-child(1) {
    left: 57%;
    width: 8px;
    height: 8px;
    animation-duration: 8074ms;
    animation-delay: 2888ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(2) {
    left: 43%;
    width: 8px;
    height: 8px;
    animation-duration: 6161ms;
    animation-delay: 1347ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(3) {
    left: 56%;
    width: 2px;
    height: 2px;
    animation-duration: 12181ms;
    animation-delay: 1411ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(4) {
    left: 8%;
    width: 10px;
    height: 10px;
    animation-duration: 7974ms;
    animation-delay: 618ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(5) {
    left: 70%;
    width: 7px;
    height: 7px;
    animation-duration: 8587ms;
    animation-delay: 2813ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(6) {
    left: 43%;
    width: 16px;
    height: 16px;
    animation-duration: 5794ms;
    animation-delay: 1305ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(7) {
    left: 11%;
    width: 3px;
    height: 3px;
    animation-duration: 5424ms;
    animation-delay: 558ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(8) {
    left: 67%;
    width: 4px;
    height: 4px;
    animation-duration: 11596ms;
    animation-delay: 421ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(9) {
    left: 16%;
    width: 3px;
    height: 3px;
    animation-duration: 12525ms;
    animation-delay: 2741ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(10) {
    left: 72%;
    width: 20px;
    height: 20px;
    animation-duration: 5155ms;
    animation-delay: 2882ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(11) {
    left: 0%;
    width: 2px;
    height: 2px;
    animation-duration: 5831ms;
    animation-delay: 412ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(12) {
    left: 29%;
    width: 11px;
    height: 11px;
    animation-duration: 9959ms;
    animation-delay: 3642ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(13) {
    left: 39%;
    width: 1px;
    height: 1px;
    animation-duration: 5127ms;
    animation-delay: 4162ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(14) {
    left: 106%;
    width: 6px;
    height: 6px;
    animation-duration: 12233ms;
    animation-delay: 4019ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(15) {
    left: 16%;
    width: 2px;
    height: 2px;
    animation-duration: 7978ms;
    animation-delay: 2523ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(16) {
    left: 48%;
    width: 25px;
    height: 25px;
    animation-duration: 5746ms;
    animation-delay: 151ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(17) {
    left: 29%;
    width: 5px;
    height: 5px;
    animation-duration: 14460ms;
    animation-delay: 4124ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(18) {
    left: 113%;
    width: 7px;
    height: 7px;
    animation-duration: 5413ms;
    animation-delay: 1004ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(19) {
    left: 27%;
    width: 9px;
    height: 9px;
    animation-duration: 8738ms;
    animation-delay: 3208ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(20) {
    left: 124%;
    width: 12px;
    height: 12px;
    animation-duration: 7585ms;
    animation-delay: 4633ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(21) {
    left: 32%;
    width: 3px;
    height: 3px;
    animation-duration: 5818ms;
    animation-delay: 1919ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(22) {
    left: 14%;
    width: 4px;
    height: 4px;
    animation-duration: 6332ms;
    animation-delay: 4254ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(23) {
    left: 36%;
    width: 7px;
    height: 7px;
    animation-duration: 9687ms;
    animation-delay: 527ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(24) {
    left: 81%;
    width: 3px;
    height: 3px;
    animation-duration: 8170ms;
    animation-delay: 1532ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(25) {
    left: 66%;
    width: 8px;
    height: 8px;
    animation-duration: 8354ms;
    animation-delay: 1599ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(26) {
    left: 61%;
    width: 22px;
    height: 22px;
    animation-duration: 11726ms;
    animation-delay: 4582ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(27) {
    left: 2%;
    width: 6px;
    height: 6px;
    animation-duration: 8201ms;
    animation-delay: 4216ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(28) {
    left: 107%;
    width: 11px;
    height: 11px;
    animation-duration: 9805ms;
    animation-delay: 510ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(29) {
    left: 28%;
    width: 2px;
    height: 2px;
    animation-duration: 9366ms;
    animation-delay: 1884ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(30) {
    left: 92%;
    width: 4px;
    height: 4px;
    animation-duration: 9966ms;
    animation-delay: 457ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(31) {
    left: 77%;
    width: 13px;
    height: 13px;
    animation-duration: 10933ms;
    animation-delay: 2839ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(32) {
    left: 24%;
    width: 7px;
    height: 7px;
    animation-duration: 7392ms;
    animation-delay: 4418ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(33) {
    left: 5%;
    width: 22px;
    height: 22px;
    animation-duration: 6378ms;
    animation-delay: 3160ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(34) {
    left: 76%;
    width: 11px;
    height: 11px;
    animation-duration: 10444ms;
    animation-delay: 4706ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(35) {
    left: 27%;
    width: 17px;
    height: 17px;
    animation-duration: 5509ms;
    animation-delay: 4886ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(36) {
    left: 11%;
    width: 21px;
    height: 21px;
    animation-duration: 6375ms;
    animation-delay: 2459ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(37) {
    left: 93%;
    width: 6px;
    height: 6px;
    animation-duration: 6317ms;
    animation-delay: 3184ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(38) {
    left: 14%;
    width: 6px;
    height: 6px;
    animation-duration: 5514ms;
    animation-delay: 1266ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(39) {
    left: 61%;
    width: 4px;
    height: 4px;
    animation-duration: 9764ms;
    animation-delay: 1166ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(40) {
    left: 28%;
    width: 5px;
    height: 5px;
    animation-duration: 10447ms;
    animation-delay: 4740ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(41) {
    left: 72%;
    width: 1px;
    height: 1px;
    animation-duration: 9511ms;
    animation-delay: 1112ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(42) {
    left: 46%;
    width: 5px;
    height: 5px;
    animation-duration: 5477ms;
    animation-delay: 2358ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(43) {
    left: 71%;
    width: 25px;
    height: 25px;
    animation-duration: 8650ms;
    animation-delay: 2256ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(44) {
    left: 9%;
    width: 10px;
    height: 10px;
    animation-duration: 10832ms;
    animation-delay: 4072ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(45) {
    left: 61%;
    width: 11px;
    height: 11px;
    animation-duration: 10534ms;
    animation-delay: 4107ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(46) {
    left: 66%;
    width: 8px;
    height: 8px;
    animation-duration: 8150ms;
    animation-delay: 3325ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(47) {
    left: 35%;
    width: 2px;
    height: 2px;
    animation-duration: 7408ms;
    animation-delay: 2040ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(48) {
    left: 0%;
    width: 15px;
    height: 15px;
    animation-duration: 9513ms;
    animation-delay: 1418ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(49) {
    left: 107%;
    width: 17px;
    height: 17px;
    animation-duration: 5040ms;
    animation-delay: 1439ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(50) {
    left: 31%;
    width: 14px;
    height: 14px;
    animation-duration: 5473ms;
    animation-delay: 373ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(51) {
    left: 26%;
    width: 9px;
    height: 9px;
    animation-duration: 8639ms;
    animation-delay: 1072ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(52) {
    left: 18%;
    width: 12px;
    height: 12px;
    animation-duration: 7925ms;
    animation-delay: 2783ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(53) {
    left: 43%;
    width: 9px;
    height: 9px;
    animation-duration: 7713ms;
    animation-delay: 4365ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(54) {
    left: 59%;
    width: 11px;
    height: 11px;
    animation-duration: 6993ms;
    animation-delay: 3431ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(55) {
    left: 20%;
    width: 1px;
    height: 1px;
    animation-duration: 10589ms;
    animation-delay: 4623ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(56) {
    left: 21%;
    width: 8px;
    height: 8px;
    animation-duration: 5832ms;
    animation-delay: 1058ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(57) {
    left: 71%;
    width: 0px;
    height: 0px;
    animation-duration: 10850ms;
    animation-delay: 2901ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(58) {
    left: 2%;
    width: 3px;
    height: 3px;
    animation-duration: 12155ms;
    animation-delay: 3629ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(59) {
    left: 100%;
    width: 17px;
    height: 17px;
    animation-duration: 6765ms;
    animation-delay: 1138ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(60) {
    left: 101%;
    width: 24px;
    height: 24px;
    animation-duration: 7043ms;
    animation-delay: 257ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(61) {
    left: 38%;
    width: 2px;
    height: 2px;
    animation-duration: 5019ms;
    animation-delay: 766ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(62) {
    left: 73%;
    width: 7px;
    height: 7px;
    animation-duration: 6666ms;
    animation-delay: 1222ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(63) {
    left: 75%;
    width: 4px;
    height: 4px;
    animation-duration: 7472ms;
    animation-delay: 3623ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(64) {
    left: 30%;
    width: 4px;
    height: 4px;
    animation-duration: 6050ms;
    animation-delay: 3286ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(65) {
    left: 61%;
    width: 21px;
    height: 21px;
    animation-duration: 5776ms;
    animation-delay: 2475ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(66) {
    left: 8%;
    width: 9px;
    height: 9px;
    animation-duration: 5779ms;
    animation-delay: 2718ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(67) {
    left: 24%;
    width: 2px;
    height: 2px;
    animation-duration: 13301ms;
    animation-delay: 2423ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(68) {
    left: 131%;
    width: 13px;
    height: 13px;
    animation-duration: 12101ms;
    animation-delay: 2791ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(69) {
    left: 132%;
    width: 2px;
    height: 2px;
    animation-duration: 8340ms;
    animation-delay: 4794ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(70) {
    left: 43%;
    width: 1px;
    height: 1px;
    animation-duration: 5855ms;
    animation-delay: 2574ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(71) {
    left: 57%;
    width: 5px;
    height: 5px;
    animation-duration: 7922ms;
    animation-delay: 1301ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(72) {
    left: 69%;
    width: 25px;
    height: 25px;
    animation-duration: 7554ms;
    animation-delay: 4848ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(73) {
    left: 17%;
    width: 21px;
    height: 21px;
    animation-duration: 9753ms;
    animation-delay: 2656ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(74) {
    left: 41%;
    width: 3px;
    height: 3px;
    animation-duration: 7734ms;
    animation-delay: 2553ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(75) {
    left: 99%;
    width: 5px;
    height: 5px;
    animation-duration: 6348ms;
    animation-delay: 1421ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(76) {
    left: 12%;
    width: 21px;
    height: 21px;
    animation-duration: 11832ms;
    animation-delay: 899ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(77) {
    left: 95%;
    width: 3px;
    height: 3px;
    animation-duration: 5893ms;
    animation-delay: 990ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(78) {
    left: 54%;
    width: 21px;
    height: 21px;
    animation-duration: 7274ms;
    animation-delay: 2720ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(79) {
    left: 27%;
    width: 6px;
    height: 6px;
    animation-duration: 11405ms;
    animation-delay: 2182ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(80) {
    left: 44%;
    width: 4px;
    height: 4px;
    animation-duration: 11440ms;
    animation-delay: 2796ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(81) {
    left: 0%;
    width: 3px;
    height: 3px;
    animation-duration: 8859ms;
    animation-delay: 1585ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(82) {
    left: 104%;
    width: 4px;
    height: 4px;
    animation-duration: 5855ms;
    animation-delay: 3167ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(83) {
    left: 28%;
    width: 11px;
    height: 11px;
    animation-duration: 5352ms;
    animation-delay: 2969ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(84) {
    left: 106%;
    width: 5px;
    height: 5px;
    animation-duration: 8595ms;
    animation-delay: 3931ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(85) {
    left: 25%;
    width: 11px;
    height: 11px;
    animation-duration: 7109ms;
    animation-delay: 2240ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(86) {
    left: 28%;
    width: 11px;
    height: 11px;
    animation-duration: 7519ms;
    animation-delay: 4675ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(87) {
    left: 49%;
    width: 7px;
    height: 7px;
    animation-duration: 8954ms;
    animation-delay: 2701ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(88) {
    left: 86%;
    width: 5px;
    height: 5px;
    animation-duration: 9855ms;
    animation-delay: 4743ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(89) {
    left: 20%;
    width: 2px;
    height: 2px;
    animation-duration: 7606ms;
    animation-delay: 1830ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(90) {
    left: 62%;
    width: 9px;
    height: 9px;
    animation-duration: 14727ms;
    animation-delay: 2490ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(91) {
    left: 63%;
    width: 25px;
    height: 25px;
    animation-duration: 5989ms;
    animation-delay: 3349ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(92) {
    left: 24%;
    width: 10px;
    height: 10px;
    animation-duration: 10434ms;
    animation-delay: 4374ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(93) {
    left: 58%;
    width: 14px;
    height: 14px;
    animation-duration: 11474ms;
    animation-delay: 4528ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(94) {
    left: 18%;
    width: 3px;
    height: 3px;
    animation-duration: 6805ms;
    animation-delay: 2503ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(95) {
    left: 6%;
    width: 5px;
    height: 5px;
    animation-duration: 7786ms;
    animation-delay: 1884ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(96) {
    left: 76%;
    width: 6px;
    height: 6px;
    animation-duration: 5329ms;
    animation-delay: 498ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(97) {
    left: 124%;
    width: 12px;
    height: 12px;
    animation-duration: 5607ms;
    animation-delay: 3859ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(98) {
    left: 73%;
    width: 19px;
    height: 19px;
    animation-duration: 5437ms;
    animation-delay: 4009ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(99) {
    left: 8%;
    width: 1px;
    height: 1px;
    animation-duration: 11278ms;
    animation-delay: 765ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(100) {
    left: 24%;
    width: 17px;
    height: 17px;
    animation-duration: 6914ms;
    animation-delay: 745ms;
    filter: blur(2px); }

@keyframes snow-drop {
  0% {
    transform: translate(0, 0);
    opacity: 0.5;
    margin-left: 0; }
  10% {
    margin-left: 15px; }
  20% {
    margin-left: 20px; }
  25% {
    transform: translate(0, 166.66667px);
    opacity: 0.75; }
  30% {
    margin-left: 15px; }
  40% {
    margin-left: 0; }
  50% {
    transform: translate(0, 333.33333px);
    opacity: 1;
    margin-left: -15px; }
  60% {
    margin-left: -20px; }
  70% {
    margin-left: -15px; }
  75% {
    transform: translate(0, 500px);
    opacity: 0.5; }
  80% {
    margin-left: 0; }
  100% {
    transform: translate(0, 666.66667px);
    opacity: 0; } }
